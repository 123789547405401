.landing-grid {
  display: grid;
  grid-template-columns: 1fr 10rem 2fr;
  grid-template-rows: auto auto;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

/* Title */
.box1 {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181717;
  font-size: 1.8rem;
}

/* Sub */
.box2 {
  grid-column: 3 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #606060;
  font-size: 1.5rem;
}

/* Arrow */
.box3 {
  grid-column: 1 / 4;
  display: flex;
  align-items: start;
  justify-content: start;
  font-size: 3.5rem;
  color: #181717;
  font-weight: 600;
}

.box4 {
  grid-column: 1 / 4;
}

/* Media query to adjust layout for smaller screens */
@media (max-width: 768px) {
  .landing-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .box1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 1.0rem;
  }

  .box2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    font-size: 1.0rem;
  }

  .box3 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .box4 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}
