.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: transparent;
    font-size: 1rem;
}

.footer-left {
    color: blue;
}

.footer-right a {
    color: black;
    text-decoration: none;
    margin-left: 2.0rem;
    font-weight: 600;
}

/* .footer-right a:hover {
    text-decoration: underline;
} */
