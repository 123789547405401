.about-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  min-height: 100vh;
}

.about-left {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
  color: #181717;
}

.about-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-title {
  color: #181717;
  font-size: 2rem;
  font-weight: bold;
}

.about-subtext {
  color: #606060;
  font-size: 1.5rem;
  line-height: 1.6;
}

.about-right img {
  width: 18rem;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.software-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  gap: 1rem;
  grid-column: 1 / span 1;
  padding: 2rem 0;
}

.software-title {
  grid-column: 1 / span 1;
  font-weight: bold;
}

.software-description {
  grid-column: 2 / span 1;
}

@media (max-width: 768px) {
  .about-container {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .about-title {
    font-size: 1.5rem;
  }

  .about-subtext {
    font-size: 1rem;
  }

  .software-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
}
