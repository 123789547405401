.navbar {
    display: flex;
    justify-content: flex-end;
    /* padding: 1rem; */
    background: transparent;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-item {
    margin-left: 3rem;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 650;
    cursor: pointer;
  }
  
  .navbar-item a:link,
  .navbar-item a:visited {
    color: inherit;
    text-decoration: none;
  }


  @media (max-width: 600px) {
    .navbar {
      justify-content: center;
    }
    .navbar-item {
      margin-left: 1rem;
      margin-right: 1rem;
    }
}