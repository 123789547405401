@import '@fontsource/inter';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #F3F3F3; /* Background color */
  scrollbar-width: none; /* Firefox */
}

.App {
  color: #181717; /* Primary color */
  padding: 5rem 10rem; /* Global padding on the left and right */
  box-sizing: border-box;
}

.secondary {
  color: #606060; /* Secondary color */
}

/* Hide scrollbar for modern browsers */
::-webkit-scrollbar {
  width: 0; /* This will hide the scrollbar */
  background-color: transparent; /* Optional: Set a background color */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Optional: Set thumb color */
}

/* Media query to adjust padding for smaller screens */
@media (max-width: 768px) {
  .App {
    padding: 2rem; /* Set padding to 0 for smaller screens */
  }
}
