.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: left;
    min-height: 100vh;
    padding-top: 3rem;
}

.image-gallery img {
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}
